import { useState } from 'react'
import * as Styles from './styles'
import EndpointItem from './EndpointItem'
import EndpointListHeader from './EndpointListHeader/EndpointListHeader'
import Tippy from '@tippyjs/react'
import { Resizable } from 're-resizable'

const EndpointList = ({ endpoints, selectedEndpoint, onSelectEndpoint, show, onResize, projectName }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const handleClick = endpoint => onSelectEndpoint(endpoint)

  return (
    <>
      <Resizable
        className="resizable-menu"
        defaultSize={{ width: 365 }}
        minWidth={365}
        maxWidth={600}
        onResize={(e, direction, ref) => {
          onResize(ref.style.width)
        }}
      >
        <Styles.EndpointList show={show}>
          <Styles.ProjectTitleContainer>
            <Tippy content={projectName}>
              <Styles.ProjectTitle>{projectName}</Styles.ProjectTitle>
            </Tippy>
          </Styles.ProjectTitleContainer>
          <EndpointListHeader onSearch={e => setSearchQuery(e.target.value)} />
          <Styles.EndpointContent>
            {endpoints
              ?.filter(e => e.path.toLowerCase().includes(searchQuery.toLowerCase()))
              .map(endpoint => (
                <EndpointItem
                  endpoint={endpoint}
                  handleClick={handleClick}
                  selectedEndpoint={selectedEndpoint}
                  key={endpoint._id}
                />
              ))}
          </Styles.EndpointContent>
        </Styles.EndpointList>
      </Resizable>
    </>
  )
}
export default EndpointList
