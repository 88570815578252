import styled, { css } from 'styled-components'

export const MainContainer = styled.div`
  position: relative;
  input {
    width: inherit;
    ${props =>
      props.fullWidth &&
      css`
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      `}
    padding: 8px 16px;
    background-color: #1e1e1e;
    border: ${props => `1px solid ${props.error ? 'red' : '#1e1e1e'}`};
    font-size: 16px;
    color: ${props => (props.error ? 'red' : '#8B949F')};
    border-radius: 40px;

    &:focus {
      outline: none;
    }

    &:placeholder  {
      font-size: 16px;
      color: #8b949f;
    }
  }

  svg {
    position: absolute;
    bottom: 33px;
    left: 16px;

    @media screen and (max-width: 500px) {
      bottom: 41px;
    }
  }
`

export const Label = styled.label`
  display: block;
  font-size: 16px;
  color: ${props => (props.error ? 'red' : 'white')};
  transition: 0.4s ease all;
  margin-bottom: 9px;
`

export const HelperTextContainer = styled.div`
  font-size: 10px;
  color: ${props => (props.error ? 'red' : 'white')};
  min-height: 10px;
`
