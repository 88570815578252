import Input from '../../components/Input'
import * as Styles from './styles'

const HeadersSection = ({ headers = [], onAdd, onChange, onRemove }) => {
  return (
    <Styles.Container>
      {headers?.length
        ? headers.map((header, index) => (
            <Styles.HeaderContainer key={index}>
              <Input
                onChange={e => onChange({ key: e.target.value, value: header.value }, index)}
                value={header.key}
                fullWidth
                placeholder="Header key"
              />
              <Input
                onChange={e => onChange({ key: header.key, value: e.target.value }, index)}
                value={header.value}
                fullWidth
                placeholder="Header value"
              />
            </Styles.HeaderContainer>
          ))
        : 'Headers not found'}
    </Styles.Container>
  )
}

export default HeadersSection
