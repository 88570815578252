import { useEffect, useState } from 'react'
import * as Styles from './styles'

const Tabs = ({ elements, onChange, defaultSelected, value }) => {
  const [selected, setSelected] = useState(defaultSelected ? defaultSelected : elements ? elements[0] : null)

  useEffect(() => {
    setSelected(elements.find(element => element.id === value))
  }, [elements, value])

  const onSelect = element => {
    setSelected(element)
    if (onChange) onChange(element)
  }

  return (
    <Styles.List>
      {elements?.map((element, index) => (
        <Styles.ListItem selected={selected?.id === element?.id} onClick={() => onSelect(element)} key={index}>
          <Styles.Label>{element.label}</Styles.Label>
        </Styles.ListItem>
      ))}
    </Styles.List>
  )
}

export default Tabs
