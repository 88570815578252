import { AiOutlineSearch } from 'react-icons/ai'
import Input from '../../../components/Input'
import * as Styles from './styles'

const EndpointListHeader = ({ onSearch }) => {
  return (
    <Styles.Container>
      <Styles.InputContainer>
        <AiOutlineSearch color="#48CFAD" />
        <Input placeholder="Search" fullWidth onChange={onSearch} />
      </Styles.InputContainer>
    </Styles.Container>
  )
}

export default EndpointListHeader
