import Tippy from '@tippyjs/react'
import MethodBadge from '../../components/MethodBadge/MethodBadge'
import * as Styles from './styles'

const EndpointItem = ({ endpoint, selectedEndpoint, handleClick }) => {
  if (endpoint.path.length > 20) {
    return (
      <Tippy content={endpoint.path} maxWidth="900px">
        <Styles.EndpointItem onClick={() => handleClick(endpoint)} active={selectedEndpoint === endpoint._id}>
          <span>{endpoint.path}</span>
          <MethodBadge method={endpoint.method}>{endpoint.method}</MethodBadge>
        </Styles.EndpointItem>
      </Tippy>
    )
  }

  return (
    <Styles.EndpointItem onClick={() => handleClick(endpoint)} active={selectedEndpoint === endpoint._id}>
      <span>{endpoint.path}</span>
      <MethodBadge method={endpoint.method}>{endpoint.method}</MethodBadge>
    </Styles.EndpointItem>
  )
}

export default EndpointItem
