import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 16px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 50px 200px 50px;
  }
`

export const InputContainer = styled.div`
  padding-left: 16px;
  position: relative;
  z-index: 0;
  flex-grow: 1;
  & > svg {
    z-index: 1;
    font-size: 16px;
    position: absolute;
    bottom: 10px;
    right: 16px;
  }

  & > div {
    width: 100%;
    input {
      margin-bottom: 0;
      padding-right: 40px;

      -moz-appearance: textfield;
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    & > div {
      display: none;
    }
  }
`
export const ButtonContainer = styled.div`
  padding-left: 5px;
  button {
    padding: 4px;
    border-radius: 8px;

    & > svg {
      font-size: 20px !important;
    }
  }
`

export const Close = styled.div`
  display: none;

  & > svg {
    font-size: 20px !important;
  }
  padding-left: 14px;

  @media screen and (max-width: 800px) {
    display: block;
    svg {
    }
  }
`
