import styled from "styled-components"

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  min-height: 100vh;
  background: radial-gradient(circle at bottom right, #48cfad -100%, #101010 70%);

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

export const SelectEndpointContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  h1 {
    color: #48cfad;
  }

  img {
    height: 150px;
  }

  @media screen and (max-width: 800px) {
    height: calc(100vh - 75px);
  }
`

export const Header = styled.div`
  background-color: #1e1e1e;
  display: flex;
  gap: 56px;
  flex-direction: column;
  padding: 10px 14px;
  align-items: center;
  padding: 24px;
  height: calc(100vh - 50px);
  width: 32px;
  position: relative;

  #mobileMenu {
    display: none;
  }

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  svg {
    color: #48cfad;
    cursor: pointer;
  }

  button {
    padding: 8px;

    &:hover {
      background: rgba(72, 207, 173, 0.25);
      border-radius: 40px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    &:hover {
      background: rgba(72, 207, 173, 0.25);
      border-radius: 40px;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;
    width: calc(100% - 48px);
    height: 20px;
    position: relative;
    justify-content: space-between;

    #mobileMenu {
      display: block;
    }

    div:nth-child(1),
    div:nth-child(2) {
      flex-direction: row;
      gap: 8px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`
