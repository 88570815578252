import { useCallback, useEffect, useRef, useState } from 'react'

const useFetch = (service, { doInitialRequest, params }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()
  const requestStarted = useRef()

  const fetchData = useCallback(
    async (params, onSuccess, onError) => {
      try {
        setLoading(true)
        const response = await service(params)
        setLoading(false)
        setData(response.data)
        if (onSuccess) onSuccess(response.data)
        return response.data
      } catch (error) {
        setError(error)
        setLoading(false)
        if (onError) onError(error)
        const customError = new Error(error)
        customError.errorMessage = error.error
        if (!onError) throw customError
      }
    },
    [service]
  )

  useEffect(() => {
    if (doInitialRequest && !data && !requestStarted.current) {
      requestStarted.current = true
      fetchData(params)
    }
  }, [doInitialRequest, fetchData, params, data])

  return { fetchData, loading, error, data, setData }
}

export default useFetch