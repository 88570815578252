import styled, { css } from 'styled-components'

export const List = styled.ul`
  list-style: none;
  padding-left: 0px;
  line-height: 35px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin: 0px;
  display: inline-block;
  margin-bottom: -16px;

  @media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    display: flex;
    margin-bottom: 0px;
  }
`

export const ListItem = styled.li`
  background: #1e1e1e;
  color: #8b949f;
  display: inline-block;
  position: relative;
  z-index: 0;
  display: inline-block;
  border-radius: 20px 20px 0px 0px;
  padding: 0px 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: #48cfad;

  :hover {
    cursor: pointer;
  }

  ${props =>
    props.selected &&
    css`
      background-color: #48cfad;
      color: #101010;
    `}

  @media screen and (max-width: 980px) {
    border-radius: 0px;
    margin: 0;

    ::before,
    :after {
      display: none;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
