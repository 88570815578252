import { useState, forwardRef } from 'react'
import * as Styles from './styles'

const Input = forwardRef(
  (
    {
      label,
      fullWidth,
      type,
      onChange,
      onBlur,
      onFocus,
      error,
      value,
      name,
      readOnly,
      maxLength,
      placeholder,
      helperText,
      Icon,
      onKeyDown,
      id,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)

    const handleChange = e => {
      if (onChange) onChange(e)
    }

    const handleOnFocus = e => {
      setFocused(true)
      if (onFocus) onFocus(e)
    }

    const handleOnBlur = e => {
      setFocused(false)
      if (onBlur) onBlur(e)
    }

    return (
      <Styles.MainContainer hasIcon={!!Icon} error={error} fullWidth={fullWidth}>
        {label && (
          <Styles.Label focused={focused} htmlFor={label} error={error}>
            {label}
          </Styles.Label>
        )}
        <input
          ref={ref}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          role={type}
          type={type}
          value={value}
          id={label || id}
          name={name}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder={placeholder}
        />
        {helperText && (
          <Styles.HelperTextContainer error={error}>
            <span>{helperText}</span>
          </Styles.HelperTextContainer>
        )}
      </Styles.MainContainer>
    )
  }
)

export default Input
